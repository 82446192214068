import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1 class="title is-1">Hello Old KomKor!</h1>
        <figure class="image is-16by9">
        <iframe width="720" height="480" src="https://www.youtube.com/embed/dQw4w9WgXcQ?si=LfeqdWGlYxSxMiGo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </figure>
      </header>
    </div>
  );
}

export default App;
